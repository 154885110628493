@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .text-shadow {
        text-shadow: 4px 4px 8px rgba(0, 0, 0, 100);
    }
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    margin: 0;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    flex: 1;
}

.h-screen {
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
}

.footer {
    margin-top: auto;
}

.sc-feUYzb,
.sc-hzhKNl,
.sc-aYaIB {
    position: inherit !important;
}

.dropdown-content {
    position: fixed !important;
}

.teste {
    position: absolute;
    z-index: 9999999999;
}

@keyframes cinematic-fade {
    0% {
        opacity: 0;
        transform: scale(1.1) translateY(-2%);
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes parallax-slide {
    0% {
        opacity: 0;
        transform: scale(1.2) translateX(5%);
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(0);
    }
}

@keyframes dramatic-zoom {
    0% {
        opacity: 0;
        transform: scale(1.4);
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes parallax-wave {
    0% {
        opacity: 0;
        clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
        transform: scale(1.2) translateX(-2%);
    }
    20% {
        opacity: 1;
        clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 0%);
    }
    40% {
        clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0%);
    }
    60% {
        clip-path: polygon(0% 0%, 0% 100%, 75% 100%, 75% 0%);
    }
    80% {
        clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
        transform: scale(1.1) translateX(-1%);
    }
    100% {
        opacity: 1;
        clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
        transform: scale(1) translateX(0);
    }
}

@keyframes ken-burns {
    0% {
        transform: scale(1) translate(0);
    }
    50% {
        transform: scale(1.08) translate(-1%, -1%);
    }
    100% {
        transform: scale(1) translate(0);
    }
}

@layer utilities {
    .animate-cinematic-fade {
        animation: cinematic-fade 1.2s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .animate-parallax-slide {
        animation: parallax-slide 1.2s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .animate-dramatic-zoom {
        animation: dramatic-zoom 1.2s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .animate-parallax-wave {
        animation: parallax-wave 1.8s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .animate-ken-burns {
        animation: ken-burns 8s ease-out infinite alternate;
    }
}

.Label-label.Label-emptyLabel:last-of-type,
.MainLayout-stickyElement.MainLayout-header.MainLayout-flexRow,
.MuiTable-root.TicksLayout-table {
    display: none !important;
}

.MainLayout-flexRow.MainLayout-stickyElement.MainLayout-leftPanel.MainLayout-ordinaryLeftPanelBorder {
    max-width: 43px !important;
    border: 0 !important;
}

.MainLayout-flexRow.MainLayout-stickyElement.MainLayout-leftPanel.MainLayout-ordinaryLeftPanelBorder {
    margin-top: -17px !important;
}

.Label-label {
    max-width: 43px !important;
}

.MainLayout-flexRow.MainLayout-stickyElement.MainLayout-leftPanel.MainLayout-brightLeftPanelBorder {
    max-width: 43px !important;
    border: 0 !important;
}

.MainLayout-relativeContainer {
    padding-top: 1px;
}

.Appointment-appointment {
    @apply glass shadow-lg;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Cell-cell {
    border-color: rgba(74, 0, 255, 0.12) !important;
}
